
import React, { useState } from 'react';

import './App.css'; 


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function App() {
 // const [phoneNumber, setPhoneNumber] = useState('');
 // const [countryCode, setCountryCode] = useState('');
 // const [areaCode, setAreaCode] = useState('');
 // const [message, setMessage] = useState('');
  const [whatsappLink, setWhatsAppLink] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [qrCodeLink, setQRCodeLink] = useState('');
  const [whatsappQRCodeLink, setWhatsAppQRCodeLink] = useState('');

  const [phoneNumberInput, setPhoneNumberInput] = useState(''); // Estado para o campo de entrada de número de telefone
  const [countryCodeInput, setCountryCodeInput] = useState('+55'); // Estado para o campo de entrada de código do país
  const [areaCodeInput, setAreaCodeInput] = useState(''); // Estado para o campo de entrada de DDD
  const [messageInput, setMessageInput] = useState(''); // Estado para o campo de entrada de mensagem
  
  

  
  
  function copyToClipboard() {
    const el = document.createElement('textarea');
    el.value = whatsappLink; // whatsappLink é a variável que contém o link
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  function generateWhatsAppLink() {
    if (phoneNumberInput && areaCodeInput && countryCodeInput) {
      const link = `https://api.whatsapp.com/send?phone=${countryCodeInput}${areaCodeInput}${phoneNumberInput}&text=${messageInput}`;
      setWhatsAppLink(link);
    }
  }

  async function generateWhatsAppQR() {
    try {
      const response = await fetch('https://qr.whatss.me/generate-qr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: whatsappLink }),
      });

      if (response.ok) {
        const data = await response.json();
        setWhatsAppQRCodeLink(data.qrCodeLink);
      } else {
        console.error('Erro ao gerar o código QR do WhatsApp.');
      }
    } catch (error) {
      console.error('Erro na solicitação: ' + error);
    }
  }

  function downloadQRCode() {
    if (whatsappQRCodeLink) {
      fetch(whatsappQRCodeLink)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'qrcode-whatsapp.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        });
    }
  }

  const complemento = 'https://url.whatss.me/';

  async function handleShortenUrl() {
    try {
      const response = await fetch('https://url.whatss.me/encurtar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ longUrl: whatsappLink }),
      });

      if (response.ok) {
        const data = await response.json();
        const urlInteira = complemento + data.shortUrl;
        setShortUrl(urlInteira);

        const qrResponse = await fetch('https://qr.whatss.me/generate-qr', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: urlInteira }),
        });

        if (qrResponse.ok) {
          const qrData = await qrResponse.json();
          setQRCodeLink(qrData.qrCodeLink);
        } else {
          console.error('Erro ao gerar o código QR.');
        }
      } else {
        console.error('Erro ao encurtar a URL.');
      }
    } catch (error) {
      console.error('Erro na solicitação: ' + error);
    }
  }

  function handlePhoneNumberInputChange(e) {
    setPhoneNumberInput(e.target.value);
  }

  function handleCountryCodeInputChange(e) {
    setCountryCodeInput(e.target.value);
  }

  function handleAreaCodeInputChange(e) {
    setAreaCodeInput(e.target.value);
  }

  function handleMessageInputChange(e) {
    setMessageInput(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    generateWhatsAppLink();
  }

  return (
      <div className="quadro">
	  
	  
	    <div className="hero-section">
      <div className="hero-content">
   
        
        
      </div>
    </div>



		
	  
	<div className="container2">
	
 
	
     <h1>Crie seu link para whatsapp  
		
		  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
        <path
          d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
          fill="white"
        />
      </svg>
		</h1>
		
	
	
	  
	  
  </div>
  


  	<div className="container3">
	<h5>O gerador de link do WhatsApp é totalmente gratuito e você gera em poucos segundos quantas vezes quiser.<br />
	compartilhe nas redes sociais e canais de vendas do seu negócio e facilite a comunicação com os clientes.
	 </h5> </div>


  
  

    <div className="conter">
		


    <div className="whatsapp-form-container">

      <div className="container">
       

	


        <form onSubmit={handleSubmit}>
          <div className="boxlado">
            <select id="bandeira"
              required
              value={countryCodeInput}
              onChange={handleCountryCodeInputChange}
            >
             
              <option value="+55"></option>
            </select>
			
			
            <select
              required
              className="ddd"
              value={areaCodeInput}
              onChange={handleAreaCodeInputChange}
            >
             <option value="">DDD</option>
              <option value="11"> 11	São Paulo	          </option>
              <option value="12"> 12	São Paulo	          </option>
              <option value="13"> 13	São Paulo	          </option>
              <option value="14"> 14	São Paulo	          </option>
              <option value="15"> 15	São Paulo	          </option>
              <option value="16"> 16	São Paulo	          </option>
              <option value="17"> 17	São Paulo	          </option>
              <option value="18"> 18	São Paulo	          </option>
              <option value="19"> 19	São Paulo	          </option>
              <option value="21"> 21	Rio de Janeiro	      </option>
              <option value="22"> 22	Rio de Janeiro	      </option>
              <option value="24"> 24	Rio de Janeiro	      </option>
              <option value="27"> 27	Espírito Santo	      </option>
              <option value="28"> 28	Espírito Santo	      </option>
              <option value="31"> 31	Minas Gerais	      </option>
              <option value="32"> 32	Minas Gerais	      </option>
              <option value="33"> 33	Minas Gerais	      </option>
              <option value="34"> 34	Minas Gerais	      </option>
              <option value="35"> 35	Minas Gerais	      </option>
              <option value="37"> 37	Minas Gerais	      </option>
              <option value="38"> 38	Minas Gerais	      </option>
              <option value="41"> 41	Paraná	              </option>
              <option value="42"> 42	Paraná	              </option>
              <option value="43"> 43	Paraná	              </option>
              <option value="44"> 44	Paraná	              </option>
              <option value="45"> 45	Paraná	              </option>
              <option value="46"> 46	Paraná	              </option>
              <option value="47"> 47	Santa Catarina	      </option>
              <option value="48"> 48	Santa Catarina	      </option>
              <option value="49"> 49	Santa Catarina	      </option>
              <option value="51"> 51	Rio Grande do Sul	  </option>
              <option value="53"> 53	Rio Grande do Sul	  </option>
              <option value="54"> 54	Rio Grande do Sul	  </option>
              <option value="55"> 55	Rio Grande do Sul	  </option>
              <option value="61"> 61	Distrito Federal/Goiás </option>
              <option value="62"> 62	Goiás	              </option>
              <option value="63"> 63	Tocantins	          </option>
              <option value="64"> 64	Goiás	              </option>
              <option value="65"> 65	Mato                  </option>
              <option value="66"> 66	Mato                  </option>
              <option value="67"> 67	Mato                  </option>
              <option value="68"> 68	Acre	              </option>
              <option value="69"> 69	Rondônia              </option>
              <option value="71"> 71	Bahia	              </option>
              <option value="73"> 73	Bahia	              </option>
              <option value="74"> 74	Bahia	              </option>
              <option value="75"> 75	Bahia	              </option>
              <option value="77"> 77	Bahia	              </option>
              <option value="79"> 79	Sergipe	              </option>
              <option value="81"> 81	Pernambuco            </option>
              <option value="82"> 82	Alagoas	              </option>
              <option value="83"> 83	Paraíba	              </option>
              <option value="84"> 84	Rio Grande do Norte	  </option> 
              <option value="85"> 85	Ceará	              </option>
              <option value="86"> 86	Piauí	              </option>
              <option value="87"> 87	Pernambuco	          </option>
              <option value="88"> 88	Ceará	              </option>
              <option value="89"> 89	Piauí	              </option>
              <option value="91"> 91	Pará	              </option>
              <option value="92"> 92	Amazonas	          </option>
              <option value="93"> 93	Pará	              </option>
              <option value="94"> 94	Pará	              </option>
              <option value="95"> 95	Roraima	              </option>
              <option value="96"> 96	Amapá	              </option>
              <option value="97"> 97	Amazonas	          </option>
              <option value="98"> 98	Maranhão	          </option>
              <option value="99"> 99	Maranhão	          </option>
			   
        </select>
            <input
              type="number"
              min="1000000"
              className="num"
              placeholder="Número de WhatsApp"
              value={phoneNumberInput}
              onChange={handlePhoneNumberInputChange}
              title="Telefone deve conter de 8 a 9 números"
              required
            />
          </div>
          <div className="boxlado">
            <input
              type="text"
              placeholder="Mensagem Personalizada"
              value={messageInput}
              onChange={handleMessageInputChange}
            />
          </div>
          <button type="submit" className="generate-button">
            Gerar Link WhatsApp
          </button>
        </form> <br /><br /><br /><br /><hr />
        {whatsappLink && (
          <div>
            <p>
              Link Gerado:{' '}
              <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                {whatsappLink}
              </a>
            </p>
            <button onClick={copyToClipboard}>Copiar</button>
            <button onClick={generateWhatsAppQR}>Gerar QR do WhatsApp</button>
            {whatsappQRCodeLink && (
              <div>
                <p>Código QR do WhatsApp:</p>
                <img src={whatsappQRCodeLink} alt="Código QR do WhatsApp" />
                <button onClick={downloadQRCode}>Baixar QR Code</button>
              </div>
            )}
            {qrCodeLink && (
              <div>
                <p>Código QR Link Encurtado:</p>
                <img src={qrCodeLink} alt="Código QR" />
              </div>
            )}
            {shortUrl ? (
              <p>
                Link Encurtado:{' '}
                <a href={shortUrl} target="_blank" rel="noopener noreferrer">
                  {shortUrl}
                </a>
              </p>
            ) : (
              <button onClick={handleShortenUrl} className="shorten-button">
                Encurtar URL
              </button>
            )}
          </div>
        )}
      </div>
	  </div>
	

	  

		  
	    <div className="cont">
	
        <div className="box2">
		
          <div className="whatsapp-chat-preview">
            <div className="chat-header">
              <img src="https://weby.website/images/service-user.png" alt="WhatsApp" />
             <h3 >{countryCodeInput}{areaCodeInput}{phoneNumberInput}</h3>
            </div>
            <div className="chat-messages">
              <div className="message received">
                <p>{messageInput}</p>
              </div>
            </div>
          </div>
		  
        </div>
		
      </div>
	  
	  

	  
	  
         </div>
		
		
			     
		
		
    
       
   <div className="espaco">
	  <div className="steps">
	 
	  <h5> Crie seu link de whatsapp </h5>
	    </div>
		
		  <div className="steps">

	  <h5> Gere um qrcode para seu link </h5>
	    </div>
		  <div className="steps">
	
	  <h5> Encurte o link gerado </h5>
	    </div>
		  <div className="steps">
	
	  <h5> Gerar qrcode do link encurtado</h5>
	    </div>
		</div>
		 

	     </div>
		
		

		 
	  
	  

	
	
	   

   
	
	   );

}

export default App;
